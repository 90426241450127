<template>
  <div id="team">
    <div class="page-title-area bg-6" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
      <div class="container">
        <div class="page-title-content">
          <h2>Our PeaceJam Team</h2>
          <ul>
            <li class="active">Meet the people behind PeaceJam!</li>
          </ul>
        </div>
      </div>
    </div>

    <section class="team-section pt-100 pb-70" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
      <div class="container-fluid">
        <div class="loading" style="height: 800px" v-if="teamType_data === undefined && !teamType_error">
          <Loading />
        </div>
        <div class="team-container" v-if="teamType_data">
          <template v-if="team_data">
            <div class="team-type" v-for="type in teamType_data.data" :key="type.id" v-if="checkType(type.id, team_data.data).length">
              <div class="section-title">
                <h2>{{type.name}}</h2>
              </div>
              <div class="team-member-wrapper">
                <div class="team-member"
                     v-for="team in team_data.data"
                     :key="team.id"
                     v-if="team.type === type.id"
                     @click="openBio(team)"
                     :class="{'peaceJam-advisors': team.type === 3}"
                >
                  <DefaultImage :id="team.image" :height="'100%'" />

                  <div class="team-description">
                    <div class="team-description-wrapper">
                      <h2>{{team.name}}</h2>
                      <span>{{team.title}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>

    <section class="team-section pt-50 pb-70 board-members">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2>Board Members</h2>
            <div class="board-members-wrapper">
              <div class="board-members-items" v-for="(item, index) in boardMembers" :key="index">{{item}}</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <modal name="bio" height="auto" width="600px">
      <div class="modal-wrapper">
        <div class="close-btn" @click="$modal.hide('bio')"><i class="fa fa-close"></i></div>
        <div class="modal-body">
          <div class="bio-name-wrapper mb-30">
            <DefaultImage :id="modal_data.image" :width="'150px'" :height="'150px'" />
            <div class="section-title position-relative ml-5">
              <div class="section-text">
                <h2>{{modal_data.name}}</h2>
                <h6 class="f-500 text-up-case letter-spacing pink-color">{{modal_data.title}}</h6>
                <div class='social-share-btns-container'>
                  <div class='social-share-btns'>
                    <a class='share-btn share-btn-twitter' :href="modal_data.twitter_url" v-if="modal_data.twitter_url" rel='nofollow' target='_blank'>
                      <i class='ion-social-twitter'></i>
                    </a>
                    <a class='share-btn share-btn-facebook' :href="modal_data.facebook_url" v-if="modal_data.facebook_url" rel='nofollow' target='_blank'>
                      <i class='ion-social-facebook'></i>
                    </a>
                    <a class='share-btn share-btn-linkedin' :href="modal_data.linkedin_url" v-if="modal_data.linkedin_url" rel='nofollow' target='_blank'>
                      <i class='ion-social-linkedin'></i>
                    </a>
                    <a class='share-btn share-btn-reddit' :href="modal_data.instagram_url" v-if="modal_data.instagram_url" rel='nofollow' target='_blank'>
                      <i class='ion-social-instagram'></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="description">
            <p class="white-space-pre-wrap" v-html="modal_data.description"></p>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import api from "../services/api";
  import DefaultImage from "../components/DefaultImage";
  import Loading from "../components/Loading";
  import useSWRV from "swrv";

  export default {
    name: "Teams",
    components: {Loading, DefaultImage},
    data() {
      return {
        loading: true,
        modal_data: [],
        boardMembers: [
          "Ngodup Tsering, representing the Dalai Lama",
          "Monica Willard (Board Chair), representing Betty Williams",
          "Aury Cuxe, representing Rigoberta Menchú Tum",
          "OSCAR ARIAS, representing himself",
          "Naomi Tutu, representing Desmond Tutu",
          "Beverly Keene, representing Adolfo Pérez Esquivel",
          "Dr. Ali Moiin, representing Shirin Ebadi,",
          "Hinrich Kaiser, representing José Ramos-Horta",
          "Liz Bernstein, representing Jody Williams",
          "Sally Milne, representing Sir Joseph Rotblat",
          "Jay Kesselee Fatormah, representing Leymah Gbowee",
          "Anjali Kochar, representing Kailash Satyarthi",
          "VALA ALNEHMI, representing Tawakkol Karman"
        ]
      }
    },
    setup() {
      const {
        data: teamType_data,
        error: teamType_error,
        isValidating: teamType_isValidating
      } = useSWRV(() => `items/team_type?status=published`, api.fetcher);

      const {
        data: team_data,
        error: team_error,
        isValidating: team_isValidating
      } = useSWRV(() => `items/team?status=published`, api.fetcher);

      return {
        teamType_data,
        teamType_error,
        teamType_isValidating,

        team_data,
        team_error,
        team_isValidating
      }
    },
    methods: {
      openBio(bio) {
        this.modal_data = bio;
        this.$modal.show('bio');
      },
      checkType(typeId, data) {
        let arr = [];
        for (let team of data) {
          if (team.type === typeId) {
            arr.push(team.id)
          }
        }
        return arr
      }
    },
    mounted() {
      window.scrollTo(0, 0);
    }
  }
</script>

<style scoped lang="scss">
  @import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);

  .page-title-area .page-title-content ul .active {
    font-size: 20px;
  }
  .page-title-area {
    background-image: url("../assets/img/team/team.png");
    background-position: center;
  }
  .team-container {
    .team-type {
      margin-bottom: 80px;

      .section-title {
        margin-bottom: 30px;
      }
    }
  }
  .team-member-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .team-member {
      width: 16.6667%;
      height: 305px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      color: #fff;

      &.peaceJam-advisors {
        width: 25%;
        height: 455px;
      }

      .team-description {
        transition: 0.5s;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.57);
      }

      .team-description-wrapper {
        transition: 0.5s;
        transform: translateY(50px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;

        h2 {
          color: #fff;
          text-align: center;
        }
      }

      &:hover .team-description {
        opacity: 1;
      }

      &:hover .team-description-wrapper {
        transform: translateY(0);
      }
    }
  }

  .modal-wrapper {
    padding: 30px;
    position: relative;

    .section-title {
      margin: 0;
    }

    .description {
      margin-top: 20px;
    }

    .bio-name-wrapper {
      display: flex;
      align-items: center;

      .section-text {
        margin: 0;
        text-align: left;
        h2 {
          font-size: 35px;
          font-weight: 600;
        }

        h6 {
          color: #FC4040;
        }
      }
    }

    .close-btn {
      position: absolute;
      top: 15px;
      right: 15px;
      border-radius: 50%;
      border: 2px solid #444;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      i {
        font-size: 20px;
        font-weight: 100;
      }
    }

    h1 {
      margin-bottom: 20px;
    }

    .modal-body {
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  }

  .social-share-btns-container {
    overflow: hidden;
    font-size: 0;
    z-index: 111;
  }


  .social-share-btns {
    display: flex;
    overflow: hidden;
    width: 100%;

    .share-btn {
      float: left;
      margin-right: 10px;
      color: #fff;
      font-size: 14px;
      line-height: 18px;
      vertical-align: middle;
      margin-bottom: 10px;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        display: inline-block;
        font-size: 20px;
        vertical-align: middle;
      }
    }
  }

  // Colors
  .share-btn {
    background-color: #95a5a6;
    &:hover {
      background-color: darken(#95a5a6, 10%);
    }
  }
  .share-btn-twitter {
    background-color: #00aced;
    &:hover {
      background-color: darken(#00aced, 10%);
    }
  }
  .share-btn-facebook {
    background-color: #3b5998;
    &:hover {
      background-color: darken(#3b5998, 10%);
    }
  }
  .share-btn-linkedin {
    background-color: #007bb6;
    &:hover {
      background-color: darken(#007bb6, 10%);
    }
  }
  .share-btn-reddit {
    background-color: #ED001C;
    &:hover {
      background-color: darken(#ED001C, 10%);
    }
  }

  .share-btn-mail {
    background-color: #f1c40f;
    &:hover {
      background-color: darken(#f1c40f, 5%);
    }
  }

  .credits {
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
    width: 100%;
    z-index: 1111;
    letter-spacing: 0.04em;
    font-size: 10px;
    text-transform: uppercase;
    a {
      color: rgba(255,255,255,0.5);
      display: inline-block;
      padding: 4px 4px;
      text-decoration: none;
      &:hover {
        color: #fff;
      }
    }
  }

  .board-members {
    background-color: #eeeeee;

    h2 {
      text-align: center;
      margin-bottom: 20px;
    }

    .board-members-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .board-members-items {
        background: #31bfd7;
        color: #fff;
        padding: 5px 15px;
        line-height: 1;
        margin-right: 10px;
        margin-bottom: 10px;
        width: calc(50% - 10px);
        text-align: center;
      }
    }
  }

  @media (max-width: 1024px) {
    .board-members .board-members-wrapper .board-members-items {
      width: 100%;
    }
  }

  @media (max-width: 1400px) {
    .team-member-wrapper {
      .team-member {
        height: 228px;

        &.peaceJam-advisors {
          width: 25%;
          height: 354px;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .team-member-wrapper {
      .team-member {
        height: 190px;

        &.peaceJam-advisors {
          height: 280px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .team-member-wrapper {
      .team-member {
        width: 25%;

        &.peaceJam-advisors {
          height: 200px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .team-member-wrapper {
      .team-member {
        height: 150px;

        &.peaceJam-advisors {
          height: 150px;
        }
      }
    }

    .modal-wrapper .modal-body {
      .col-md-7 {
        display: flex;
        justify-content: center;

        .default-img {
          margin-bottom: 20px;
        }
      }
    }
    .modal-wrapper {
      .section-title {
        margin-left: 0!important;
      }

      .bio-name-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column-reverse;
      }
    }
  }

  @media (max-width: 550px) {
    .team-member-wrapper {
      .team-member {
        width: 33.33%;
        height: 172px;

        &.peaceJam-advisors {
          width: 33.33%;
          height: 172px;
        }
      }
    }
  }

  @media (max-width: 450px) {
    .team-member-wrapper {
      .team-member {
        width: 50%;
        height: 172px;

        &.peaceJam-advisors {
          width: 50%;
        }
      }
    }
  }
</style>
